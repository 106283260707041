import {
  PageData,
  PageMetaDataList,
  FooterType,
  QuickNavType,
  MarketplaceItemData,
  MarketplaceItem,
  InboxMessage,
  WeekMenu,
  MenuHistoryData,
  Additive,
  LunchCartDataType,
  OutletCartType,
  OutletHistorySpecificType,
  OutletHistoryGeneral,
  LexiconSearch,
  LunchCartOrderType,
  BonusCodeData,
} from '@/types';
import { RequestInit } from 'next/dist/server/web/spec-extension/request';
import { ParsedUrlQuery } from 'querystring';
import { signOut } from 'next-auth/react';
import { UserData } from 'next-auth';

const API_URL = process.env.WAGTAIL_API_URL ?? process.env.NEXT_PUBLIC_API_URL;
const API_VERSION = process.env.WAGTAIL_API_VERSION ?? process.env.NEXT_PUBLIC_API_VERSION;
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export class WagtailApiResponseError extends Error {
  response: Response;

  constructor(res: Response, url: string, params: any) {
    super(`${res.status} - ${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`);
    this.name = 'WagtailApiResponseError';
    this.response = res;
  }
}

type Params = Record<string, string | readonly string[]>;

export async function getRequest<T>(
  url: string,
  params: ParsedUrlQuery = {},
  options: Partial<RequestInit> = {},
) {
  let headers = options?.headers ?? {};
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  };

  const queryString = new URLSearchParams(params as any);
  const res = await fetch(`${API_URL}/${API_VERSION}/${url}?${queryString.toString()}`, {
    ...options,
    headers,
  });

  if (res.status < 200 || res.status >= 300) {
    if (res.status === 401 || res.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }

    const error = new WagtailApiResponseError(res, url, params);
    throw error;
  }

  const json: T = await res.json();
  return {
    headers: res.headers,
    json,
  };
}

export async function getPage(
  path: string,
  params: Params = {},
  options: Partial<RequestInit> = {},
) {
  const page = await getRequest<PageData>(
    'pages/find/',
    { ...params, html_path: path !== '/[...path]' && path ? path : '/' },
    options,
  );
  return page;
}

export async function getMenu(options: Partial<RequestInit> = {}) {
  return getRequest<PageMetaDataList[]>(
    `wagtailmenus_api/v1/main_menu/?current_url=${BASE_URL}`,
    undefined,
    options,
  );
}

export async function getFooterSettings(options: Partial<RequestInit> = {}) {
  return getRequest<FooterType>(`settings/footer/`, undefined, options);
}

export async function getQuickNav(options: Partial<RequestInit> = {}) {
  return getRequest<QuickNavType>(`settings/quicknav/`, undefined, options);
}

export async function getAllPages() {
  const pages = await getRequest<PageMetaDataList>('pages/');
  return pages;
}

export async function getPagePreview(
  content_type: string,
  token: string,
  params: any = {},
  options: any = {},
) {
  // get id out of token
  const id = token.replace(/^id=([\d]+).*/gm, '$1');

  const res = await getRequest<PageData>(
    `page_preview/${id}/`,
    { ...params, content_type, token },
    options,
  );

  return res;
}

export async function getFlatMenu(handle: string) {
  const res = await getRequest<PageMetaDataList[]>(
    `wagtailmenus_api/v1/flat_menu/?current_url=${BASE_URL}&handle=${handle}`,
  );

  return res;
}

export async function searchSite(query: ParsedUrlQuery, options: Partial<RequestInit> = {}) {
  const result = await getRequest<PageMetaDataList>('pages/', query, options);
  return result;
}

export async function searchLexicon(query: ParsedUrlQuery, options: Partial<RequestInit> = {}) {
  const result = await getRequest<LexiconSearch>('pages/', query, options);
  return result.json.items;
}

export async function getMyProfile(options: Partial<RequestInit> = {}) {
  return getRequest<UserData>(`users/me/`, undefined, options);
}

export async function getInbox(options: Partial<RequestInit> = {}) {
  return getRequest<InboxMessage[]>(`inbox/`, undefined, options);
}

export async function getInboxMessage(id: number, options: Partial<RequestInit> = {}) {
  return getRequest<InboxMessage>(`inbox/${id}/`, undefined, options);
}

export async function getInboxUnreadNumber(options: Partial<RequestInit> = {}) {
  return getRequest<{ count: number }>(`inbox/num-unread/`, undefined, options);
}

export async function setInboxAllRead(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/inbox/mark-read/all/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/inbox/mark-read/all/', undefined);
    throw error;
  }

  return submit.status;
}

export async function getBonusCode(options: Partial<RequestInit> = {}) {
  return getRequest<BonusCodeData>(`werder-bremen/`, undefined, options);
}

export async function getCurrentWeekLunch(options: Partial<RequestInit> = {}) {
  return getRequest<WeekMenu>(`WeekMenuPage/`, undefined, options);
}

export async function getSpecificWeekLunch(date: string, options: Partial<RequestInit> = {}) {
  return getRequest<WeekMenu>(`lunch/menu/week/`, { date }, options);
}

export async function getAllAdditives(options: Partial<RequestInit> = {}) {
  return getRequest<Additive[]>(`lunch/additives/`, undefined, options);
}

export async function signUrls(url: string, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/sign-urls/sign/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify({ url }),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/sign-urls/sign/', undefined);
    throw error;
  }
  return submit;
}

export async function getLunchOrderHistory(
  month: string,
  year: string,
  options: Partial<RequestInit> = {},
) {
  return getRequest<MenuHistoryData>(`lunch/orders/`, { year, month }, options);
}

export async function addToLunchCart(data: any, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/lunch/cart/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if ((submit.status < 200 || submit.status >= 300) && submit.status !== 400) {
    const error = new WagtailApiResponseError(submit, '/lunch/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function removeFromLunchCart(authToken: string, data: number[]) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/lunch/cart/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/lunch/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function submitLunchCart(authToken: string, data: LunchCartOrderType[]) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/lunch/cart/submit/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/lunch/cart/submit/', undefined);
    throw error;
  }

  return submit;
}

export async function getLunchCart(options: Partial<RequestInit> = {}) {
  return getRequest<LunchCartDataType>(`lunch/cart/`, undefined, options);
}

export async function getOutletCart(options: Partial<RequestInit> = {}) {
  return getRequest<OutletCartType>(`shop/cart/`, undefined, options);
}

export async function getOutletOrders(options: Partial<RequestInit> = {}) {
  return getRequest<OutletHistoryGeneral[]>(`shop/orders/`, undefined, options);
}

export async function getSpecificOutletOrder(id: number, options: Partial<RequestInit> = {}) {
  return getRequest<OutletHistorySpecificType>(`shop/orders/${id}`, undefined, options);
}

export async function addToOutletCart(
  data: { amount: number; article_item_id: number },
  authToken: string,
) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function removeFromOutletCart(authToken: string, data: { article_item_id: number }) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/', undefined);
    throw error;
  }
  return submit;
}

export async function submitOutletCart(authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/shop/cart/submit/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/shop/cart/submit/', undefined);
    throw error;
  }

  return submit;
}

export async function submitForm(data: any) {
  const submit = await getRequest('forms/', undefined, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return submit;
}

export async function validateToken(token: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/validate/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(token),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/validate/', undefined);
    throw error;
  }
  return submit;
}

export async function submitPasswordReset(data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/confirm/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/confirm/', undefined);
    throw error;
  }

  return submit;
}

export async function submitRequestPasswordReset(data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/password-reset/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/password-reset/', undefined);
    throw error;
  }
  return submit;
}

export async function submitPasswordChange(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/users/me/set-password/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/users/me/set-password/', undefined);
    throw error;
  }

  return submit;
}

export async function getOwnMarketPlaceItems(options: Partial<RequestInit> = {}) {
  return getRequest<MarketplaceItemData>(`OwnMarketplaceItems/`, undefined, options);
}

export async function getOwnMarketPlaceItem(id: number, options: Partial<RequestInit> = {}) {
  return getRequest<MarketplaceItem>(`OwnMarketplaceItems/${id}/`, undefined, options);
}

export async function createOwnMarketPlaceItems(data: FormData, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/OwnMarketplaceItems/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: data,
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/OwnMarketplaceItems/', undefined);
    throw error;
  }
  return submit;
}

export async function updateOwnMarketPlaceItems(data: FormData, authToken: string, id: number) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/OwnMarketplaceItems/${id}/`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: data,
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/OwnMarketplaceItems/', undefined);
    throw error;
  }

  return submit;
}

export async function deleteOwnMarketPlaceItems(authToken: string, id: number) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/OwnMarketplaceItems/${id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (submit.status < 200 || submit.status >= 300) {
    const error = new WagtailApiResponseError(submit, '/OwnMarketplaceItems/', undefined);
    throw error;
  }

  return submit.status;
}

export async function submitEventRegistration(data: Object, authToken: string) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/events/registrations/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/events/registrations/', undefined);
    throw error;
  }

  return submit;
}

export async function submitEmailConsent(authToken: string, data: Object) {
  const submit = await fetch(`${API_URL}/${API_VERSION}/users/me/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(data),
  });
  if (submit.status < 200 || submit.status >= 300) {
    if (submit.status === 401 || submit.status === 403) {
      signOut({ redirect: true, callbackUrl: '/auth/signin' });
    }
    const error = new WagtailApiResponseError(submit, '/users/me/', undefined);
    throw error;
  }

  return submit;
}
