import React, { useRef, useState } from 'react';
import Link from 'next/link';
import Hamburger from '@components/Hamburger';
import clsx from 'clsx';
import { removeBaseUrl } from 'utils/url';
import type { PageMetaDataList } from '@/types';
import { getSession } from 'next-auth/react';
import { getMenu } from '@api/wagtail';
import { useQuery } from '@tanstack/react-query';
import SearchSvg from 'icons/search-magnify.svg';
import AddSvg from 'icons/add.svg';

async function getMenuData(): Promise<PageMetaDataList[]> {
  const session = await getSession();
  const authheader = { Authorization: `Token ${session?.token}` };
  const menuData = await getMenu({ headers: authheader }).then(response => response.json);
  return menuData;
}

function NavMainMobile() {
  const [showSubNav, setSubNav] = useState({ showSubNav: false, navId: 0 });
  const [showSubSubNav, setSubSubNav] = useState({ showSubNav: false, navSubId: 0 });
  const navRef = useRef<HTMLElement | null>(null);
  const { isError, isLoading, data } = useQuery(['menuData'], () => getMenuData(), {
    staleTime: 1000 * 60 * 5,
  });
  const currentPath = `${window.location.pathname}/`;
  if (isLoading) return <div className='nav-mobile' />;
  if (isError) return <div className='nav-mobile' />;

  return (
    <>
      <nav className='nav-mobile' id='nav-mobile' ref={navRef} role='navigation'>
        <div className='nav-mobile__container'>
          {/** @ts-expect-error @ */}
          {data.items.map((item, index) => (
            <div className='nav-mobile__element-container' key={`${item.page.slug}`}>
              <div className='nav-mobile__element'>
                <Link
                  href={removeBaseUrl(item.href)}
                  passHref
                  className={clsx('nav-mobile__link', {
                    active: currentPath.includes(removeBaseUrl(item.href)),
                  })}
                >
                  {item.text}
                </Link>
                {item.children.length ? (
                  <button
                    type='button'
                    className='nav-mobile__expand'
                    onClick={() =>
                      setSubNav({
                        showSubNav: showSubNav.navId !== index ? true : !showSubNav.showSubNav,
                        navId: index,
                      })
                    }
                  >
                    <AddSvg className='navigation__subnav-icon' />
                  </button>
                ) : null}
              </div>
              {item.children.length ? (
                <div
                  className={`nav-mobile__subnav-container ${
                    showSubNav.showSubNav && showSubNav.navId === index ? 'show' : ''
                  }`}
                >
                  {/** @ts-expect-error @ */}
                  {item.children.map((child, i) => (
                    <div
                      className='nav-mobile__subnav-element-container'
                      key={`${child.page.slug}`}
                    >
                      <div className='nav-mobile__subnav-element'>
                        <Link
                          href={removeBaseUrl(child.href)}
                          passHref
                          className={clsx('nav-mobile__subnav-link', {
                            active: currentPath.includes(removeBaseUrl(child.href)),
                          })}
                        >
                          {child.text}
                        </Link>
                        {child.children.length ? (
                          <button
                            type='button'
                            className='nav-mobile__expand'
                            onClick={() =>
                              setSubSubNav({
                                showSubNav:
                                  showSubSubNav.navSubId !== i ? true : !showSubSubNav.showSubNav,
                                navSubId: i,
                              })
                            }
                          >
                            <AddSvg className='navigation__subnav-icon' />
                          </button>
                        ) : null}
                      </div>
                      {child.children.length ? (
                        <div
                          className={`nav-mobile__subnav-container ${
                            showSubSubNav.showSubNav && showSubSubNav.navSubId === i ? 'show' : ''
                          }`}
                        >
                          {/** @ts-expect-error @ */}
                          {child.children.map(subchild => (
                            <div
                              className='nav-mobile__subnav-element-container'
                              key={`${subchild.page.slug}`}
                            >
                              <div className='nav-mobile__subnav-element'>
                                <Link
                                  href={removeBaseUrl(subchild.href)}
                                  passHref
                                  className={clsx('nav-mobile__subnav-link', {
                                    active: currentPath.includes(removeBaseUrl(subchild.href)),
                                  })}
                                >
                                  {subchild.text}
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className='nav-mobile__bottom'>
          <div className='nav-mobile__icon-container'>
            <Link href='/search' passHref className='mobile-nav__icon'>
              <SearchSvg className='mobile-nav__icon-svg magnify' />
            </Link>
          </div>
        </div>
      </nav>
      <Hamburger
        onClick={() => {
          navRef?.current?.classList.toggle('nav--open');
        }}
        aria-controls='nav-mobile'
        aria-label='Navigation umschalten'
      />
    </>
  );
}

export default NavMainMobile;
