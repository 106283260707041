import { signUrls, WagtailApiResponseError } from '@api/wagtail';
import { addBaseUrl } from '@utils/url';
import clsx from 'clsx';
import { getSession } from 'next-auth/react';
import React, { useEffect } from 'react';

type Props = { html?: string; className?: string };

async function getSignedUrl(url: string): Promise<string> {
  const session = await getSession();
  const authtoken = `Token ${session?.token}`;
  try {
    const signedUrl = await signUrls(url, authtoken).then(response => response.json());
    return signedUrl.url;
  } catch (error) {
    if (!(error instanceof WagtailApiResponseError)) {
      throw error;
    }
    if (error.response.status >= 500) {
      throw error;
    }
    return 'error';
  }
}

async function signUrl(originalUrl: string | null) {
  if (originalUrl) {
    const res = await getSignedUrl(addBaseUrl(originalUrl));
    if (res !== 'error') {
      window.location.href = res;
    }
  }
}

function RawHtml(props: Props) {
  const { html = '', className } = props;
  useEffect(() => {
    const documentLinks = document.querySelectorAll(`.document-url`);
    documentLinks.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
        signUrl(link.getAttribute('href'));
      });
    });
  }, []);

  /* eslint-disable-next-line react/no-danger */
  return <div className={clsx(className)} dangerouslySetInnerHTML={{ __html: html }} />;
}

export default RawHtml;
