import React from 'react';
import RawHtml from '@components/RawHtml';

type Props = {
  type: string;
  text: string;
};

function UserNotification(props: Props) {
  const { type, text } = props;
  return (
    <div className={`user-notification--${type}`}>
      <RawHtml className='richtext-wrapper' html={text} />
    </div>
  );
}

export default UserNotification;
