import React, { PropsWithChildren } from 'react';
import NavMainAside from '@components/NavMainAside';
import mediaQuery from 'hooks/mediaQuery';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import LoginInformation from '@components/LoginInformation';
import LoginBackLink from '@components/LoginBackLink';
import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

type Props = {};

function Layout(props: PropsWithChildren<Props>) {
  const { children } = props;
  const isDesktop = mediaQuery('(min-width: 65em)');
  const router = useRouter();
  let isSignIn = false;
  if (router.asPath.includes('/auth/signin')) {
    isSignIn = true;
  }

  const session = useSession();
  return (
    <div className='layout'>
      <div className='layout__content'>
        {isDesktop &&
        !isSignIn &&
        !session.data?.user?.password?.expired &&
        session.status === 'authenticated' ? (
          <NavMainAside key={router.asPath} />
        ) : null}
        <div className='layout__main'>
          {!isSignIn && session.status === 'authenticated' ? <Header key={router.asPath} /> : null}
          {!isSignIn && session.status !== 'authenticated' ? <LoginBackLink /> : null}
          {session.data?.user?.password?.show_warning ||
          (session.data?.user?.password?.expired && session.status === 'authenticated') ? (
            <LoginInformation session={session.data} />
          ) : null}
          <Main>{children}</Main>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
