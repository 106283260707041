import React from 'react';
import UserNotification from '@components/UserNotification';
import { Session } from 'next-auth';

type Props = {
  session: Session;
};

function LoginInformation(props: Props) {
  const { session } = props;

  let type = 'warning';
  let text = '';
  let expiringPasswordDays = 0;

  if (session.user.password.expired) {
    type = 'error';
    text =
      'Dein aktuelles Passwort ist abgelaufen, um MIA weiter zu verwenden ändere bitte dein Passwort und logge dich erneut ein.';
  }
  if (!session.user.password.expired && session.user.password.show_warning) {
    expiringPasswordDays = session.user.password.remaining_lifetime;
    type = 'warning';
    text = `Das aktuelle Passwort ist nur noch ${expiringPasswordDays} Tage gültig. Bitte ändere
      dein Passwort <a href="/user/change-password">hier</a>.`;
  }

  return <UserNotification type={type} text={text} />;
}
export default LoginInformation;
